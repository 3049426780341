<template>
    <div id="article">
        <div class="wrapper">
            <div class="container">
                <h1>{{ title }}</h1>
                <div class="preview">
                    <div v-if="isYoutube && cookies" class="youtube videoWrapper">
                        <iframe v-for="(youtubeId, y) in youtubeIds" :key="y"
                                :src="`https://youtube.com/embed/${youtubeId}`"
                                :style="{backgroundImage: `url('${path}')`,width: '100%'}"></iframe>
                    </div>
                    <video v-else-if="isVideo" controls :src="path"></video>
                    <a :href="`https://www.youtube.com/watch?v=${youtubeId}`" target="_blank" v-else-if="isYoutube && !cookies" style="display: block">
                        <img :src="path" :alt="title" style="display: block" />
                    </a>
                    <img v-else :src="path" :alt="title" />
                </div>
                <div class="youtube-link" v-if="isYoutube && !cookies">
                    <div class="cookies">
                        <p>Waarom kan ik de video niet op de website bekijken?<br>
                            <span class="small">Om YouTube videos te mogen laten zien, moet je akkoord gaan met het cookiebeleid.
                            <br>
                            Je kunt de video nog wel bekijken als je op de onderstaande link klikt.</span>
                        </p>
                        <p>
                            <a :href="`https://www.youtube.com/watch?v=${youtubeId}`" target="_blank">Klik hier om op YouTube de video te bekijken.</a>
                        </p>
                    </div>
                </div>
                <div class="text">
                    <div class="content" v-for="(content, c) in contents" :key="c" v-html="content"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "src/assets/css/variables";

#article {
    padding: 20px;

    & .text {
        display: flex;
        margin-top: 30px;

        & .content {
            width: 100%;
            padding-right: 50px;
        }
    }

    & .videoWrapper {

        & iframe {
            background-color: $black;
            background-size: cover;
            aspect-ratio: 16/9;
            min-height: 30vh;
        }
    }

    & img {
        width: 100%;
        max-height: 80vh;
        object-fit: cover;
        object-position: top;
        aspect-ratio: 16/12;
    }

    & .youtube-link {

        & .cookies {
            color: $white;
            background-color: $black;
            margin: 0 0 20px;
            font-style: italic;
            padding: 20px;

            & p {
                font-size: 16px;
                line-height: 16px;
                margin: 0;

                & a {
                    margin-top: 20px;
                    display: block;
                }
            }

            & .small {
                font-size: 12px;
                line-height: 12px;
            }
        }
    }

}
</style>
<script>
import {absolute} from "@/App";

export default {
    name: "ArticleView",
    props: {
        resources: {
            type: Object
        }
    },
    metaInfo() {
        return {
            title: this.article?.title ?? "Kristal Jansen",
            titleTemplate: `%s | Portfolio | ${this.resources?.title || "Kristal Jansen"}`,
            meta: [
                {name: 'title', content: this.title ?? "Home"},
                {name: 'og:title', content: this.title ?? "Home"},
                {name: 'description', content: this.description ?? this.resources?.description ?? "Kristel Jansen"},
                {name: 'og:description', content: this.description ?? this.resources?.description ?? "Kristel Jansen"},
                {name: 'keywords', content: this.resources?.tags ?? ""},
                {name: 'og:keywords', content: this.resources?.tags ?? ""},
            ]
        }
    },
    created() {
        this.$emit('loaded', false)
        this.$store.dispatch('loadArticle', this.item)
    },
    watch: {
        article: {
            handler() {
                this.$emit('loaded', true)
                this.$emit('title', this.title)
            },
            deep: true
        },
        $route() {
            this.$store.dispatch('loadArticle', this.item)
        }
    },
    computed: {
        item() {
            return this.$route.params.item ?? null;
        },
        article() {
            return this.$store.getters.article ?? null
        },
        title() {
            return this.article?.title ?? ""
        },
        content() {
            return this.article?.content ?? null
        },
        image() {
            const image = this.article?.image ?? null
            return image === ""? null : image;
        },
        cookies() {
            return this.$store.getters.cookies ?? false
        },
        contents() {
            if (this.content != null) {
                let content = `${this.content ?? "<p></p>"}`.replaceAll('/cockpit', absolute("cockpit"));
                if (content.indexOf('[button]') !== -1) {
                    this.button = true;
                    content = content.replaceAll('[button]', '')
                }
                if (content.indexOf('[split]') !== -1) {
                    content = content.replaceAll("[split]", "</p>[split]<p>")
                    return content.replaceAll("<p></p>", "").split('[split]')
                }
                return [content];
            }
            return []
        },
        path() {
            let image = this.image?.path ?? null
            if (image != null) {
                return absolute(`cockpit/storage/uploads${image}`);
            }
            if (this.isYoutube) {
                if (this.youtubeId != null) {
                    return `https://img.youtube.com/vi/${this.youtubeId}/mqdefault.jpg`
                }
                return "";
            }
            return null
        },
        isVideo() {
            return this.image?.video ?? false
        },
        isYoutube() {
            return this.youtube_link != null
        },
        youtube_link() {
            return this.article?.youtube_link ?? null
        },
        youtubeIds() {
            let array = [];
            if (this.isYoutube) {
                (this.youtube_link ?? "").split(',').forEach(link => {
                    let url = new URL(link)
                    array.push(url.searchParams.get("v"));
                })
            }
            return array
        },
        youtubeId() {
            if (this.youtubeIds.length > 0) {
                return this.youtubeIds[0] ?? null
            }
            return null
        },
    }
}
</script>
